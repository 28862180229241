.bgLineIcon {
  position: absolute;
  top: 0;
  left: 40px;
  width: 1200.5px;
  height: 7511px;
}
.thumbnailVrindavanFinalRemoIcon,
.thumbnailVrindavanFinalRemoIcon1 {
  position: relative;
  width: 89.1px;
  height: 93px;
  object-fit: cover;
}
.thumbnailVrindavanFinalRemoIcon1 {
  width: 250.8px;
  height: 74.7px;
  object-fit: cover;
}
.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.home {
  position: relative;
  line-height: 28px;
  text-transform: capitalize;
}
.menuBar,
.menuBar1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.menuBar1 {
  align-items: flex-start;
  gap: var(--gap-61xl);
}
.menuBar {
  position: absolute;
  top: 14px;
  left: 61px;
  align-items: center;
  gap: 511px;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  font-family: var(--font-jost);
}
.image,
.imageIcon {
  width: 100%;
  height: 720px;
}
.imageIcon {
  position: relative;
  object-fit: cover;
}
.image {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 0;
}
.yourPremier {
  margin: 0;
}
.yourPremierEventContainer1 {
  line-break: anywhere;
  width: 100%;
}
.yourPremierEventContainer {
  position: relative;
  line-height: 125%;
  display: flex;
  align-items: center;
  width: 733px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.heroSlide,
.heroText {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.heroText {
  margin: 0 !important;
  top: calc(50% - 80px);
  left: 97px;
  width: 733px;
  z-index: 1;
}
.heroSlide {
  top: 131px;
  left: 0;
  background-color: var(--color-dimgray-100);
  width: 100%;
  height: 720px;
  overflow: hidden;
  gap: var(--gap-3xs);
  text-align: left;
  font-size: 64px;
  color: var(--color-white);
}
.escapeToTranquilityContainer,
.nestledInThe {
  position: relative;
  display: flex;
  align-items: center;
}
.escapeToTranquilityContainer {
  letter-spacing: 0.02em;
  line-height: 125%;
  font-family: var(--font-dm-serif-display);
  width: 500px;
}
.nestledInThe {
  font-size: var(--font-size-xl);
  line-height: 28px;
  color: var(--color-dimgray-200);
  width: 368px;
}
.getFreeEstimate1 {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 125%;
  font-weight: 600;
}
.vectorIcon {
  position: relative;
  width: 17.2px;
  height: 16px;
}
.getFreeEstimate {
  width: 176.1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.button,
.button1,
.subContent {
  display: flex;
  flex-direction: column;
}
.button1 {
  border-radius: var(--br-lg);
  background: linear-gradient(90deg, #16393a, #314f50);
  box-shadow: 0 10px 20px rgba(192, 192, 192, 0.35);
  align-items: center;
  justify-content: center;
  padding: var(--padding-7xl) var(--padding-12xl);
}
.button,
.subContent {
  align-items: flex-start;
  justify-content: flex-start;
}
.button {
  width: 277px;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-white);
}
.subContent {
  width: 441px;
  height: 447px;
  gap: var(--gap-24xl);
}
.imageIcon1 {
  position: relative;
  width: 417px;
  height: 447px;
  object-fit: cover;
}
.subContentParent {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.aboutUs {
  position: absolute;
  top: 1051px;
  left: calc(50% - 429px);
  width: 858px;
  height: 447px;
  text-align: left;
  color: var(--color-teal-100);
  font-family: var(--font-jost);
}
.aWorldOf,
.header {
  display: flex;
  align-items: center;
}
.aWorldOf {
  position: relative;
  font-size: var(--font-size-3xl);
  letter-spacing: 0.01em;
  line-height: 150%;
  font-family: var(--font-jost);
  color: var(--color-dimgray-200);
  justify-content: center;
  width: 737px;
}
.header {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.maskGroupIcon {
  position: relative;
  width: 96px;
  height: 94.8px;
  object-fit: cover;
}
.naturalBeauty {
  position: relative;
  line-height: 125%;
}
.ourSprawlingGrounds {
  position: relative;
  font-size: var(--font-size-3xl);
  letter-spacing: 0.01em;
  line-height: 150%;
  font-family: var(--font-jost);
  color: var(--color-dimgray-200);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 358px;
}
.maskGroupContainer,
.maskGroupParent {
  flex-direction: column;
  align-items: center;
}
.maskGroupParent {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-6xl);
}
.maskGroupContainer {
  gap: var(--gap-10xl);
}
.frameParent,
.maskGroupContainer,
.ourVenue {
  display: flex;
  justify-content: flex-start;
}
.frameParent {
  flex-direction: row;
  align-items: flex-start;
  gap: 42px;
  font-size: var(--font-size-6xl);
  color: var(--color-teal-200);
}
.ourVenue {
  position: absolute;
  top: 1698px;
  left: 61px;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-61xl);
}
.maskGroupIcon3 {
  position: relative;
  width: 1030px;
  height: 644.9px;
  object-fit: cover;
  z-index: 0;
}
.frameChild {
  position: relative;
  border-radius: var(--br-181xl);
  width: 62px;
  height: 62px;
  overflow: hidden;
  flex-shrink: 0;
}
.frameDiv,
.frameGroup,
.propertyTour {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameGroup {
  margin: 0 !important;
  position: absolute;
  top: 280px;
  left: 31px;
  flex-direction: row;
  gap: 843px;
  z-index: 1;
}
.frameDiv,
.propertyTour {
  flex-direction: column;
  position: relative;
  gap: var(--gap-3xs);
}
.propertyTour {
  position: absolute;
  top: 2381.8px;
  left: 125px;
  align-items: center;
  gap: var(--gap-61xl);
}
.maskGroupIcon4 {
  position: relative;
  width: 1030px;
  height: 600px;
  object-fit: cover;
  z-index: 0;
}
.weddings {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 125%;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.weddingsSangeetsHaldhi {
  position: relative;
  font-size: var(--font-size-3xl);
  letter-spacing: 0.01em;
  line-height: 150%;
  font-family: var(--font-jost);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 737px;
}
.weddingsParent {
  margin: 0 !important;
  position: absolute;
  top: 437px;
  left: 146px;
  align-items: center;
  gap: 6px;
  z-index: 1;
}
.designedForYou,
.maskGroupParent1,
.weddingsParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.maskGroupParent1 {
  align-items: flex-start;
  position: relative;
  gap: var(--gap-3xs);
  font-size: 50px;
  color: var(--color-white);
}
.designedForYou {
  position: absolute;
  top: 3394.7px;
  left: 125px;
  align-items: center;
  gap: var(--gap-61xl);
}
.ourAmenities {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 125%;
}
.maskGroupIcon5 {
  position: relative;
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.maskGroupParent2 {
  position: absolute;
  top: calc(50% - 60px);
  left: calc(50% - 88px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.frameWrapper {
  position: relative;
  width: 358px;
  height: 150px;
}
.maskGroupParent3,
.maskGroupParent4 {
  display: flex;
  justify-content: flex-start;
}
.maskGroupParent3 {
  position: absolute;
  top: calc(50% - 60px);
  left: calc(50% - 47px);
  flex-direction: column;
  align-items: center;
  gap: var(--gap-3xs);
}
.maskGroupParent4 {
  left: calc(50% - 79px);
}
.maskGroupParent4,
.maskGroupParent5,
.maskGroupParent6 {
  position: absolute;
  top: calc(50% - 60px);
  flex-direction: column;
  align-items: center;
  gap: var(--gap-3xs);
}
.maskGroupParent5 {
  left: calc(50% - 101px);
  display: flex;
  justify-content: flex-start;
}
.maskGroupParent6 {
  left: calc(50% - 96px);
}
.frameContainer,
.maskGroupParent6,
.maskGroupParent7 {
  display: flex;
  justify-content: flex-start;
}
.maskGroupParent7 {
  position: absolute;
  top: calc(50% - 60px);
  left: calc(50% - 116px);
  flex-direction: column;
  align-items: center;
  gap: var(--gap-3xs);
}
.frameContainer {
  width: 1154px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 40px;
  font-size: var(--font-size-6xl);
}
.headerParent {
  align-items: center;
  gap: var(--gap-61xl);
}
.amenities,
.headerParent,
.heading {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.amenities {
  position: absolute;
  top: 4429px;
  left: 0;
  background: linear-gradient(110.58deg, #1e4040, #236769);
  width: 100%;
  height: 720px;
  overflow: hidden;
  align-items: flex-start;
  padding: 88px 63px;
  box-sizing: border-box;
  color: var(--color-white);
}
.heading {
  width: 737px;
  align-items: center;
  gap: var(--gap-5xs);
}
.groupChild,
.groupInner,
.groupItem,
.rectangleDiv {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-gainsboro);
  width: 486px;
  height: 429px;
}
.groupInner,
.groupItem,
.rectangleDiv {
  top: 459px;
  width: 676px;
}
.groupInner,
.rectangleDiv {
  top: 0;
  left: 516px;
  width: 685px;
}
.rectangleDiv {
  top: 459px;
  left: 706px;
  width: 495px;
}
.rectangleParent {
  position: relative;
  width: 1201px;
  height: 888px;
}
.evets {
  position: absolute;
  top: 5348.7px;
  left: calc(50% - 601px);
  width: 1201px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-61xl);
  color: #2f7274;
}
.evets,
.heading1,
.wedLoveToContainer {
  display: flex;
  align-items: center;
}
.wedLoveToContainer {
  position: relative;
  font-size: var(--font-size-3xl);
  letter-spacing: 0.01em;
  line-height: 150%;
  font-family: var(--font-jost);
  color: var(--color-dimgray-200);
  width: 737px;
}
.heading1 {
  width: 1199px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frameInner {
  position: relative;
  border-radius: 0 40px 0 160px;
  background-color: var(--color-gainsboro);
  width: 585px;
  height: 509px;
}
.lineDiv,
.name {
  position: absolute;
}
.name {
  top: 0;
  left: calc(50% - 233px);
  line-height: 125%;
  display: flex;
  align-items: center;
  width: 103.7px;
}
.lineDiv {
  top: 80.5px;
  left: -0.5px;
  border-top: 1px solid var(--color-darkslategray);
  box-sizing: border-box;
  width: 467px;
  height: 1px;
}
.nameParent {
  position: relative;
  width: 466px;
  height: 81px;
}
.message,
.phoneNumber {
  position: absolute;
  top: 0;
  left: calc(50% - 233px);
  line-height: 125%;
  display: flex;
  align-items: center;
  width: 196.6px;
}
.message {
  width: 109.6px;
}
.button2,
.groupParent {
  display: flex;
  flex-direction: column;
}
.groupParent {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6xl);
}
.button2 {
  border-radius: var(--br-lg);
  background: linear-gradient(90deg, #16393a, #314f50);
  box-shadow: 0 10px 20px rgba(192, 192, 192, 0.35);
  width: 471px;
  align-items: center;
  justify-content: center;
  padding: var(--padding-7xl) var(--padding-12xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-white);
  font-family: var(--font-jost);
}
.frameParent1,
.rectangleGroup {
  display: flex;
  justify-content: flex-start;
}
.frameParent1 {
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-16xl);
}
.rectangleGroup {
  flex-direction: row;
  align-items: flex-start;
  gap: 72px;
  text-align: left;
  font-size: var(--font-size-6xl);
  color: var(--color-darkslategray);
}
.contactUs,
.vrindavanRetreat {
  position: absolute;
  display: flex;
}
.contactUs {
  top: 6602.7px;
  left: 39px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-61xl);
}
.vrindavanRetreat {
  top: 0;
  left: 0;
  line-height: 125%;
  align-items: center;
  width: 327.5px;
}
.logo1 {
  position: relative;
  width: 327.5px;
  height: 50px;
  font-size: 40px;
  color: var(--color-darkslategray);
  font-family: var(--font-dm-serif-display);
}
.whereMomentsBecome {
  display: flex;
  align-items: center;
  width: 393px;
}
.copyrightVrindavan,
.whereMomentsBecome {
  position: relative;
  letter-spacing: 0.01em;
  line-height: 150%;
}
.interno,
.subContent1 {
  display: flex;
  flex-direction: column;
}
.subContent1 {
  width: 393px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
}
.interno {
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-31xl);
}
.contact1,
.ssFarmMysuruKarnatakaContainer {
  position: relative;
  line-height: 125%;
  display: flex;
  align-items: center;
  width: 87px;
}
.ssFarmMysuruKarnatakaContainer {
  font-size: var(--font-size-3xl);
  letter-spacing: 0.01em;
  line-height: 150%;
  font-family: var(--font-jost);
  color: var(--color-dimgray-200);
  width: 306px;
}
.contact,
.footer {
  width: 306px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 26px;
  font-size: var(--font-size-6xl);
  color: var(--color-darkslategray);
  font-family: var(--font-dm-serif-display);
}
.footer {
  position: absolute;
  top: 7511px;
  left: 0;
  border-top: 1px solid var(--color-burlywood);
  box-sizing: border-box;
  width: 1280px;
  flex-direction: row;
  padding: var(--padding-21xl) 0 var(--padding-21xl) 43px;
  gap: 329px;
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--color-dimgray-200);
  font-family: var(--font-jost);
}
.vrindavanRetreatDesign {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 7781px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-17xl);
  color: var(--color-teal-300);
  font-family: var(--font-dm-serif-display);
}
.linkStyle {
  text-decoration: none;
  color: black;
}
.linkStylePlain {
  text-decoration: none;
  color: white;
}
.heroSlide4 {
  border-radius: 0 var(--br-31xl) 0 100px;
  background-color: var(--color-dimgray-100);
  width: 575px;
  height: 450px;
  overflow: hidden;
  flex-shrink: 0;
}
