@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;600&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-jost: Jost;
  --font-dm-serif-display: "DM Serif Display";

  /* font sizes */
  --font-size-base: 16px;
  --font-size-5xl: 24px;
  --font-size-6xl: 25px;
  --font-size-sm: 14px;
  --font-size-xl: 20px;
  --font-size-lg: 18px;
  --font-size-3xl: 22px;
  --font-size-17xl: 36px;

  /* Colors */
  --color-white: #fff;
  --color-dimgray-100: #565656;
  --color-dimgray-200: #4d5053;
  --color-darkslategray: #292f36;
  --color-burlywood: rgba(224, 171, 134, 0.8);
  --color-teal-100: #2d7072;
  --color-teal-200: #296d6f;
  --color-teal-300: #266b6e;
  --color-gainsboro: #d9d9d9;
  --color-black: #000;

  /* Gaps */
  --gap-lg: 18px;
  --gap-3xs: 10px;
  --gap-31xl: 50px;
  --gap-16xl: 35px;
  --gap-6xl: 25px;
  --gap-10xl: 29px;
  --gap-24xl: 43px;
  --gap-61xl: 80px;
  --gap-5xs: 8px;

  /* Paddings */
  --padding-31xl: 50px;
  --padding-7xl: 26px;
  --padding-12xl: 31px;
  --padding-21xl: 40px;

  /* Border radiuses */
  --br-lg: 18px;
  --br-31xl: 50px;
  --br-181xl: 200px;
}
