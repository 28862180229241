.line,
.line1,
.line2,
.line3 {
  position: absolute;
  top: -0.2px;
  left: -0.2px;
  border-right: 0.5px solid var(--color-burlywood);
  box-sizing: border-box;
  width: 0.5px;
  height: 6432.5px;
}
.line1,
.line2,
.line3 {
  left: 99.8px;
}
.line2,
.line3 {
  left: 199.8px;
}
.line3 {
  left: 299.8px;
}
.thumbnailVrindavanFinalRemoIcon,
.thumbnailVrindavanFinalRemoIcon1 {
  position: relative;
  width: 50px;
  height: 52px;
  object-fit: cover;
}
.thumbnailVrindavanFinalRemoIcon1 {
  width: 150px;
  height: 42px;
}
.bgLine,
.logo {
  position: absolute;
}
.logo {
  top: 25px;
  left: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.bgLine {
  top: 0;
  left: calc(50% - 149.5px);
  width: 300px;
  height: 6432px;
}
.imageIcon {
  position: relative;
  width: 100%;
  height: 559px;
  object-fit: cover;
  z-index: 0;
}
.yourPremier {
  margin: 0;
}
.yourPremierEventContainer1 {
  line-break: anywhere;
  width: 100%;
}
.yourPremierEventContainer {
  position: relative;
  line-height: 125%;
  display: flex;
  align-items: center;
  width: 375px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.heroSlide,
.heroText {
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.heroText {
  margin: 0 !important;
  top: calc(50% - 39.5px);
  z-index: 1;
}
.heroSlide {
  top: 107px;
  background-color: var(--color-dimgray-100);
  height: 559px;
  overflow: hidden;
  gap: var(--gap-3xs);
  font-size: 32px;
  color: var(--color-white);
}
.escapeToTranquilityContainer {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 125%;
  font-family: var(--font-dm-serif-display);
  display: flex;
  align-items: center;
  width: 372px;
}
.imageIcon1 {
  position: relative;
  width: 100%;
  height: 278px;
  object-fit: cover;
}
.imageWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.nestledInThe {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 28px;
  color: var(--color-dimgray-200);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 318px;
}
.getFreeEstimate1 {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 125%;
  font-weight: 600;
}
.vectorIcon {
  position: relative;
  width: 17.2px;
  height: 16px;
}
.getFreeEstimate {
  width: 176.1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.button1 {
  border-radius: var(--br-lg);
  background: linear-gradient(90deg, #16393a, #314f50);
  box-shadow: 0 10px 20px rgba(192, 192, 192, 0.35);
  width: 225px;
  height: 58px;
  padding: var(--padding-7xl) var(--padding-12xl);
  box-sizing: border-box;
}
.button,
.button1,
.subContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.button {
  width: 277px;
  font-size: var(--font-size-sm);
  color: var(--color-white);
}
.subContent {
  position: absolute;
  top: 716px;
  left: 0;
  width: 375px;
  height: 758px;
  gap: var(--gap-24xl);
  color: var(--color-teal-100);
  font-family: var(--font-jost);
}
.ourVenue {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 125%;
}
.aWorldOf,
.header {
  display: flex;
  align-items: center;
}
.aWorldOf {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: 0.01em;
  line-height: 150%;
  font-family: var(--font-jost);
  color: var(--color-dimgray-200);
  justify-content: center;
  width: 251px;
}
.header {
  width: 375px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.maskGroupIcon {
  position: relative;
  width: 80px;
  height: 79px;
  object-fit: cover;
}
.naturalBeauty {
  position: relative;
  line-height: 125%;
}
.ourSprawlingGrounds {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: 0.01em;
  line-height: 150%;
  font-family: var(--font-jost);
  color: var(--color-dimgray-200);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
}
.maskGroupParent {
  width: 300px;
  justify-content: center;
  gap: var(--gap-6xl);
}
.maskGroupContainer,
.maskGroupParent,
.ourVenue1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.maskGroupContainer {
  justify-content: flex-start;
  gap: var(--gap-10xl);
}
.ourVenue1 {
  justify-content: center;
}
.frameParent,
.headerParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frameParent {
  align-items: flex-start;
  gap: var(--gap-6xl);
  font-size: var(--font-size-xl);
  color: var(--color-teal-200);
}
.headerParent {
  position: absolute;
  top: 1524px;
  left: 0;
  align-items: center;
  gap: var(--gap-31xl);
}
.heroSlide1 {
  position: relative;
  background-color: var(--color-dimgray-100);
  width: 375px;
  height: 210.9px;
  overflow: hidden;
  flex-shrink: 0;
}
.headerGroup {
  position: absolute;
  top: 2569.9px;
  left: -1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-31xl);
}
.header2,
.ourResortBoasts {
  align-items: center;
  justify-content: center;
}
.ourResortBoasts {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: 0.01em;
  line-height: 150%;
  font-family: var(--font-jost);
  color: var(--color-dimgray-200);
  display: flex;
  width: 300px;
}
.header2 {
  flex-direction: column;
  gap: var(--gap-3xs);
}
.header2,
.heroText1,
.weddings {
  width: 375px;
  display: flex;
}
.weddings {
  position: relative;
  line-height: 125%;
  align-items: center;
  justify-content: center;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.heroText1 {
  margin: 0 !important;
  position: absolute;
  top: calc(50% + 83.5px);
  left: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 0;
}
.weddingsSangeetsHaldhi {
  position: relative;
  letter-spacing: 0.01em;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 375px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.heroText2 {
  margin: 0 !important;
  position: absolute;
  top: calc(50% + 126.7px);
  left: 0;
  width: 375px;
  z-index: 1;
  font-size: var(--font-size-base);
  font-family: var(--font-jost);
}
.headerContainer,
.heroSlide2,
.heroText2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.heroSlide2 {
  border-radius: var(--br-31xl);
  background-color: var(--color-dimgray-100);
  width: 375px;
  height: 375px;
  overflow: hidden;
  flex-shrink: 0;
  position: relative;
  gap: var(--gap-3xs);
  color: var(--color-white);
}
.headerContainer {
  position: absolute;
  top: 3018.8px;
  left: 0;
  gap: var(--gap-31xl);
}
.maskGroupIcon3 {
  position: relative;
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.frameDiv {
  position: absolute;
  top: calc(50% - 41px);
  left: calc(50% - 59.5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.frameWrapper {
  position: relative;
  width: 167px;
  height: 150px;
}
.maskGroupParent1 {
  position: absolute;
  top: calc(50% - 41px);
  left: calc(50% - 32.5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.frameContainer {
  position: relative;
  width: 169px;
  height: 150px;
}
.maskGroupParent2 {
  position: absolute;
  top: calc(50% - 41px);
  left: calc(50% - 54.5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.frameWrapper1 {
  position: relative;
  width: 168px;
  height: 150px;
}
.maskGroupParent3,
.maskGroupParent4 {
  flex-direction: column;
  align-items: center;
  gap: var(--gap-3xs);
}
.maskGroupParent3 {
  position: absolute;
  top: calc(50% - 41px);
  left: calc(50% - 70.5px);
  display: flex;
  justify-content: flex-start;
}
.maskGroupParent4 {
  top: calc(50% - 42px);
  left: calc(50% - 65px);
}
.frameGroup,
.maskGroupParent4,
.maskGroupParent5 {
  position: absolute;
  display: flex;
  justify-content: flex-start;
}
.maskGroupParent5 {
  top: calc(50% - 41px);
  left: calc(50% - 78px);
  flex-direction: column;
  align-items: center;
  gap: var(--gap-3xs);
}
.frameGroup {
  top: 192px;
  left: 14px;
  width: 347px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 11px;
}
.aWorldOf1,
.header3 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.aWorldOf1 {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: 0.01em;
  line-height: 150%;
  font-family: var(--font-jost);
  width: 280px;
}
.header3 {
  position: absolute;
  top: 54px;
  left: 0;
  width: 375px;
  flex-direction: column;
  gap: var(--gap-3xs);
  font-size: var(--font-size-5xl);
  font-family: var(--font-dm-serif-display);
}
.amenities,
.heroSlide3 {
  width: 100%;
  overflow: hidden;
}
.amenities {
  position: absolute;
  top: 3704px;
  left: 0;
  background: linear-gradient(110.58deg, #1e4040, #236769);
  height: 805px;
  font-size: var(--font-size-lg);
  color: var(--color-white);
  font-family: var(--font-jost);
}
.heroSlide3 {
  position: relative;
  background-color: var(--color-dimgray-100);
  height: 375px;
  flex-shrink: 0;
}
.headerParent1 {
  position: absolute;
  top: 4609px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-31xl);
}
.celebrateLifeSurrounded {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 125%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 375px;
}
.heroSlide4 {
  border-radius: 0 var(--br-31xl) 0 100px;
  background-color: var(--color-dimgray-100);
  width: 375px;
  height: 375px;
  overflow: hidden;
  flex-shrink: 0;
}
.name {
  position: absolute;
  top: 0;
  left: calc(50% - 150px);
  line-height: 125%;
  display: flex;
  align-items: center;
  width: 66.8px;
}
.groupChild {
  position: absolute;
  top: 77.5px;
  left: -0.5px;
  border-top: 1px solid var(--color-darkslategray);
  box-sizing: border-box;
  width: 301px;
  height: 1px;
}
.nameParent {
  position: relative;
  width: 300px;
  height: 78px;
}
.phoneNumber {
  position: absolute;
  top: 0;
  left: calc(50% - 150px);
  line-height: 125%;
  display: flex;
  align-items: center;
  width: 227px;
}
.groupItem {
  position: absolute;
  top: 74.5px;
  left: -0.5px;
  border-top: 1px solid var(--color-darkslategray);
  box-sizing: border-box;
  width: 301px;
  height: 1px;
}
.phoneNumberParent {
  position: relative;
  width: 300px;
  height: 75px;
}
.groupInner {
  position: absolute;
  top: 61.5px;
  left: -0.5px;
  border-top: 1px solid var(--color-darkslategray);
  box-sizing: border-box;
  width: 301px;
  height: 1px;
}
.eMailParent {
  position: relative;
  width: 300px;
  height: 62px;
}
.message {
  position: absolute;
  top: 0;
  left: calc(50% - 150px);
  line-height: 125%;
  display: flex;
  align-items: center;
  width: 143px;
}
.button2,
.groupParent {
  display: flex;
  flex-direction: column;
}
.groupParent {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6xl);
}
.button2 {
  width: 300px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-white);
  font-family: var(--font-jost);
}
.frameParent1,
.headerParent2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frameParent1 {
  align-items: flex-end;
  gap: var(--gap-16xl);
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-darkslategray);
}
.headerParent2 {
  position: absolute;
  top: 5246px;
  left: 0;
  align-items: center;
  gap: var(--gap-31xl);
}
.contact1,
.ssFarmMysuruKarnatakaContainer {
  position: relative;
  line-height: 125%;
  display: flex;
  align-items: center;
  width: 87px;
}
.ssFarmMysuruKarnatakaContainer {
  font-size: var(--font-size-base);
  letter-spacing: 0.01em;
  line-height: 150%;
  font-family: var(--font-jost);
  color: var(--color-dimgray-200);
  width: 306px;
}
.contact {
  border-top: 1px solid var(--color-burlywood);
  box-sizing: border-box;
  width: 375px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px 0 0 39px;
  gap: var(--gap-3xs);
}
.vrindavanRetreat {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 125%;
  display: flex;
  align-items: center;
  width: 327.5px;
}
.logo1 {
  position: relative;
  width: 327.5px;
  height: 30px;
  font-size: var(--font-size-5xl);
  color: var(--color-darkslategray);
  font-family: var(--font-dm-serif-display);
}
.whereMemoriesBecome {
  display: flex;
  align-items: center;
  width: 275px;
}
.copyrightVrindavan,
.whereMemoriesBecome {
  position: relative;
  letter-spacing: 0.01em;
  line-height: 150%;
}
.subContent1 {
  width: 293px;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
}
.contactParent,
.interno,
.subContent1 {
  display: flex;
  flex-direction: column;
}
.interno {
  width: 375px;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-31xl);
  box-sizing: border-box;
  font-size: var(--font-size-base);
  color: var(--color-dimgray-200);
  font-family: var(--font-jost);
}
.contactParent {
  position: absolute;
  top: 6432px;
  left: 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 23px;
  text-align: left;
  font-size: var(--font-size-6xl);
  color: var(--color-darkslategray);
}
.phoneVersion {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 6816px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--color-teal-300);
  font-family: var(--font-dm-serif-display);
}
.linkStylePlain {
  text-decoration: none;
  color: white;
}
